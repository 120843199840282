import { unstable_createMuiStrictModeTheme as createMuiStrictModeTheme } from '@mui/material'
import { COUNTRY_AT, COUNTRY_DE } from './constants'
import { getCountry } from './utils'

const settingsDE = {
  primaryColor: '#069ac4',
  backgroundDefault: '#031224',
  backgroundPaper: '#162336',
  backgroundNavbar: '#020f1e'
}

const settingsAT = {
  primaryColor: '#D52E2E',
  backgroundDefault: '#000000',
  backgroundPaper: '#1B1B1B',
  backgroundNavbar: '#0E0E0E'
}

export default function theme () {
  return getCountry() === COUNTRY_AT ? themeAT : themeDE
}

const options = (country) => {
  const settings = getCountry() === COUNTRY_AT ? settingsAT : settingsDE

  return {
    palette: {
      mode: 'dark',
      primary: {
        main: settings.primaryColor
      },
      background: {
        default: settings.backgroundDefault,
        paper: settings.backgroundPaper
      },
      navbar: settings.backgroundNavbar
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    typography: {
      h1: {
        fontSize: '2rem',
        fontWeight: 500
      },
      h2: {
        fontSize: '1.5rem'
      },
      h3: {
        fontSize: '1.25rem',
        fontWeight: 500
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
          }
        }
      }
    }
  }
}

const themeDE = createMuiStrictModeTheme(options(COUNTRY_DE))
const themeAT = createMuiStrictModeTheme(options(COUNTRY_AT))
